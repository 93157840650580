<template>
  <div style="background: #eaeaea;height:100%;">
    <div>
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/lunbo1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img
            src="../../assets/F2CE8085-E4E7-4214-8473-6D61924D1A0D.png"
            alt=""
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            src="../../assets/31F3B990-F1D3-415c-8AAD-E85731C33CA1.png"
            alt=""
          />
        </van-swipe-item>
      </van-swipe> -->
    </div>
    <!-- <div style="width: 95%; margin: auto;">
      <div>
        <div style="margin: 0 5px 5px 7px; font-size: 15px; color: #323233">
          <div>
            <div class="title">疫情防控明白卡</div>
            <div class="title_one">当前，多省市相继出现新冠肺炎确诊病例，国内新冠肺炎疫情呈多点爆发趋势，我省多地市也出现确诊病例。为有效控制和降低疫情传播风险，维护群众身体健康，现将有关事项通告如下：</div>
            <div class="title_two">一、所有市外务工、学习、生活等人员，请做到非必要不返淇，如确需返淇，请提前3天向目的地所在村（社区）进行报备，并配合社区落实隔离管控、健康监测等相应的疫情防控措施。</div>
            <div class="title_two">二、即日起，所有人员非必要不离鹤。不要前往中高风险地区，确需前往的，请务必提前向所在村（社区）和工作单位报备，并做好个人防护；返淇后，应主动向所在村（社区）和工作单位报备相关情况，配合落实相应的隔离管控措施。</div>
          </div>
        </div>
        <div style="text-align:center;">
          <img style="width: 180px; height: 180px"
            :src="shenwaiUrl"
            @click="openHealth(shenwaiUrl)" />
        </div>

      </div>
    </div> -->
    <div class="my-list">
      <div class="my-lists"
        :border="false"
        :column-num="3">
        <!--  -->
        <!-- <div class="lists">
          <router-link to="/register/newAdd">
            <img src="../../assets/yeguang.png"
              alt="" />
            <span>居民信息登记</span>
          </router-link>
        </div> -->
        <!-- <div class="lists">
          <router-link to="/register/yimiao">
            <img src="../../assets/疫苗.png"
              alt="" />
            <span>疫苗接种登记</span>
          </router-link>
        </div> -->
        <div class="lists">
          <router-link to="/register/fangyi/yimiaoNew/ymShouye">
            <img src="../../assets/疫苗.png"
              alt="" />
            <span>疫苗接种登记</span>
          </router-link>
        </div>
        <!-- <div class="lists">
          <router-link to="/register/luo">
            <img src="../../assets/返淇.png"
              alt="" />
            <span>返淇人员报备</span>
          </router-link>
        </div> -->

        <div class="lists">
          <router-link to="/register/fangyi/fanqiNew/fqShouye">
            <img src="../../assets/返淇.png"
              alt="" />
            <span>返(来)淇人员报备</span>
          </router-link>
        </div>
        <!-- <div class="lists">
          <div @click="fanqi()">
            <img src="../../assets/返淇.png"
              alt="" />
            <span>返(来)淇人员报备</span>
          </div>
        </div> -->
        <!-- <div class="lists">
          <router-link to="/register/chuqi">
            <img src="../../assets/chuqi.png"
              alt="" />
            <span>出淇人员报备</span>
          </router-link>
        </div> -->
        <div class="lists">
          <router-link to="/register/fangyi/chuqiNew/cqShouye">
            <img src="../../assets/chuqi.png"
              alt="" />
            <span>在外人员登记</span>
          </router-link>
        </div>
        <!-- <div class="lists">
          <router-link to="/register/geli">
            <img src="../../assets/xuqiu.png"
              alt="" />
            <span>管控人员报备</span>
          </router-link>
        </div> -->
        <div class="lists">
          <router-link to="/register/fangyi/hesuan/hesuanAdd">
            <img src="../../assets/xuqiu.png"
              alt="" />
            <span>核酸检测登记</span>
          </router-link>
        </div>
        <div class="lists">
          <router-link to="/register/fangyiType">
            <img src="../../assets/矛盾隐患.png"
              alt="" />
            <span>场所防控措施</span>
          </router-link>
        </div>

        <!-- <div class="lists">
          <router-link to="/register/changsuoAdd/:seId/:ekind">
            <img src="../../assets/矛盾隐患.png"
              alt="" />
            <span>场所防控措施</span>
          </router-link>
        </div> -->
      </div>
    </div>
    <!-- <router-link to="/">
      <div class="nameResume">返回首页</div>
    </router-link> -->
    <van-dialog v-model="showTc"
      title="请长按二维码识别进入"
      @confirm='confirm'>
      <div style="text-align:center;">
        <img style="width: 180px; height: 180px;"
          :src="shenwaiUrl"
          class="qrcode"
          :show-menu-by-longpress="true"
          @click="openHealth(shenwaiUrl)" />
      </div>

    </van-dialog>
    <!-- <div style="text-align:center;">
      <image style="width: 180px; height: 180px"
        :src="shenwaiUrl"
        @click="openHealth(shenwaiUrl)"></image>
    </div> -->
  </div>
</template>
<script>

import Vue from "vue";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import wx from 'weixin-js-sdk'
export default {
  name: "ImagePreview ",
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      shenwaiUrl: 'https://api.qxzhqm.cn//upload/images/2022/4/ca920572-3cd.png',
      // shenwaiUrl: 'https://api.qxzhqm.cn//upload/images/2022/4/168e16cc-068.jpg',
      showTc: false,
    };
  },
  methods: {
    openHealth: function (row) {
      console.log(row);

      ImagePreview({
        // images:this.newImgUrl,
        images: [row],
        startPosition: 0,
        closeOnPopstate: true,
      });
    },
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    fanqi: function () {
      console.log(123321);
      this.showTc = true
    },
  },
};
</script>
<style scoped>
.qrcode {
  user-select: auto;
  pointer-events: auto;
  -webkit-touch-callout: auto;
}
</style>
<style>
.my-swipe .van-swipe-item {
  color: #fff;
  text-align: center;
}
.my-swipe img {
  width: 100%;
}
.my-list {
  font-size: 14px;
}
.my-lists .lists {
  position: relative;
  width: 90%;
  margin: 25px 5%;
  background: #fff;
  border-radius: 15px;
}
.my-lists span {
  position: absolute;
  top: 30px;
  left: 130px;
  font-size: 18px;
  color: #000;
}
.my-lists img {
  width: 20%;
  padding: 10px;
}
.jianjie {
  padding-bottom: 20px;
  border-top: 10px solid #f8f5f5;
  border-bottom: 10px solid #f8f5f5;
}
.jianjie div {
  display: inline-block;
}
.jianjieA {
  position: relative;
  margin-left: 5%;
}
.jianjieA img {
  width: 75%;
}
.makebox div {
  /* display: flex;
  align-items: center; */
  display: inline-block;
  position: relative;
  width: 45%;
  height: 40px;
  padding: 10px 8px;
  line-height: 40px;
  color: #333;
  border-top: 1px solid #f4f3f3;
  /* border: 1px solid #000; */
}
.makebox span {
  width: 80%;
  font-size: 14px;
  float: left;
}
.makebox img {
  position: absolute;
  top: 10px;
  float: right;
  width: 20%;
}
.nameResume {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background: #0188fb;
  padding: 8px;
  width: 50px;
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  border-radius: 15px;
  color: #fff;
}
.title {
  text-align: center;
  padding: 10px 0;
  font-size: 20px;
  color: #2fa7ea;
  font-weight: 550;
}
.title_one {
  margin: 0 10px;
  line-height: 30px;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}
.title_two {
  margin: 0 10px;
  line-height: 30px;
  padding-top: 10px;
}
</style>